import React from "react";
import styles from "./Line2.module.css";

const Line2 = () => {
  return (
    <div className={styles.raya2}></div>
  );
};

export default Line2;
